.logged-in {
	margin-top: 50px;	
	.admin-menu {
		min-height: 50px;
		transition: all ease 300ms;
		background: #21333e;
		* {
			transition: all ease 300ms;
			font-size: 14px;
		}
		a.navbar-brand {
			background: #349886;
			color: #fff;
		}
		.navbar-nav > li > a {
			color: #d1dee7;
			text-transform: lowercase; 
		}
		.dropdown-toggle, .dropdown-toggle:hover , .dropdown .open, .dropdown .open:hover {
			background-color: #415e6d;
		}
		.dropdown-menu > li > a {
			color: #21333e;
		}
	} 
	.admin-menu-scrolled { 
		transition: all ease 300ms;
		* {
			font-size: 12px;
			transition: all ease 300ms;
		}
	} 
}  